import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import StyledLink from "@components/Link"
import SubscribeForm from "@components/SubscribeForm"

const Intro = () => {
  return (
    <section>
      <div className="container--boxed">
        <div className="lg:bg-slate-100 dark:lg:bg-slate-800 grid grid-cols-1 lg:grid-cols-2">
          <div className="pt-12 lg:py-14 lg:pl-11 lg:pr-10 order-2 lg:order-1">
            <div className="prose prose-slate dark:prose-invert">
              <p>
                Dr. Andrew Ng is a globally recognized leader in AI (Artificial
                Intelligence). He is Founder of{" "}
                <a href="https://www.deeplearning.ai/" target="_blank">
                  DeepLearning.AI
                </a>
                , Founder &amp; CEO of{" "}
                <a href="https://landing.ai/" target="_blank">
                  Landing AI
                </a>
                , General Partner at <a href="https://aifund.ai/">AI Fund</a>,
                Chairman and Co-Founder of{" "}
                <a href="https://www.coursera.org">Coursera</a> and an Adjunct
                Professor at Stanford University’s Computer Science Department.
              </p>
              <p>
                As a pioneer in machine learning and online education, Dr. Ng
                has changed countless lives through his work in AI, and has
                authored or co-authored over 200 research papers in machine
                learning, robotics and related fields. In 2023, he was named
                to the Time100 AI list of the most influential AI persons in
                the world.
              </p>
            </div>
            <div className="mt-8">
              <StyledLink
                variant="arrow"
                url={{
                  type: "internal",
                  href: "/about/",
                }}
              >
                Learn more
              </StyledLink>
            </div>
            <div className="mt-12">
              <p className="text-slate-700 dark:text-slate-300 mb-3 prose prose-slate dark:prose-invert">
                Get{" "}
                <a
                  href="https://read.deeplearning.ai/the-batch/tag/letters/"
                  target="_blank"
                >
                  Andrew’s letters
                </a>{" "}
                delivered to your inbox every week.
              </p>
              <SubscribeForm />
            </div>
          </div>
          <div className="order-1 lg:order-2 flex justify-center lg:justify-end">
            <StaticImage
              src="../images/andrew-ng-homepage.jpg"
              alt="Andrew Ng"
              loading="eager"
              width={524}
              height={564}
              quality={100}
              className=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
