import React from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "gatsby"
import StyledLink from "@components/Link"

type Props = {
  title: string
  image?: {
    gatsbyImage?: IGatsbyImageData
    alt?: string
  }
  excerpt?: string
  link: {
    type: "internal" | "external"
    url: string
  }
}

const PostCardLarge: React.FC<Props> = ({ title, image, excerpt, link }) => {
  return (
    <article className="relative grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-9">
      <div>
        {image?.gatsbyImage && (
          <GatsbyImage
            image={getImage(image.gatsbyImage)}
            alt={image?.alt || title}
            className="shadow-md rounded-sm"
            imgClassName="rounded-sm"
          />
        )}
      </div>
      <div className="flex flex-col md:py-2 lg:py-6">
        <h2 className="text-lg sm:text-xl md:text-xl lg:text-2xl font-semibold dark:text-slate-200">
          {title}
        </h2>
        {excerpt && (
          <p
            className="mt-3 text-[15px] text-slate-600 dark:text-slate-400 line-clamp-3 lg:line-clamp-5"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></p>
        )}
        {link.type === "internal" ? (
          <Link to={link.url} className="absolute top-0 left-0 w-full h-full" />
        ) : (
          <a href={link.url} className="absolute top-0 left-0 w-full h-full" />
        )}
        <div className="grow"></div>
        <div className="mt-4">
          <StyledLink
            variant="arrow"
            url={{ type: link?.type, href: link?.url }}
          >
            Read more
          </StyledLink>
        </div>
      </div>
    </article>
  )
}

export default PostCardLarge
