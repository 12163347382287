import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import Intro from "@components/Intro"
import SectionHeader from "@components/SectionHeader"
import PostCard from "@components/PostCard"
import PostCardLarge from "@components/PostCardLarge"
import Seo from "@components/Seo"

const IndexPage = ({ data }) => {
  const { publications, projects, courses, currentPage } = data

  return (
    <Layout>
      <Seo
        yoastSeo={currentPage?.seo}
        image={
          currentPage?.featuredImage?.node?.localFile?.childImageSharp?.original
            ?.src
        }
      />

      <div className="md:mt-4">
        <Intro />
      </div>

      {publications?.edges?.length && (
        <section className="mt-20">
          <div className="container--boxed">
            <SectionHeader
              title="Publications"
              link={{
                type: "internal",
                url: "/publications/",
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:gap-6 lg:gap-10 mt-10">
              {publications.edges.map(({ node }) => (
                <PostCard
                  key={node.id}
                  title={node.title}
                  excerpt={node.excerpt.replace(/<[^>]+>/g, "")}
                  image={{
                    gatsbyImage:
                      node.featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData,
                    alt: node.featuredImage?.node?.altText || node.title,
                  }}
                  link={{
                    type: "internal",
                    url: `/publications/${node.slug}/`,
                  }}
                  date={{
                    unFormatted: node.dateMachineReadable,
                    formatted: node.date,
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      )}

      {projects?.edges?.length && (
        <section className="mt-24">
          <div className="container--boxed">
            <SectionHeader
              title="Projects"
              link={{
                type: "internal",
                url: "/projects/",
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-9 mt-10">
              {projects.edges.map(({ node }) => (
                <PostCardLarge
                  key={node.id}
                  title={node.title}
                  excerpt={node.excerpt.replace(/<[^>]+>/g, "")}
                  image={{
                    gatsbyImage:
                      node.featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData,
                    alt: node.featuredImage?.node?.altText || node.title,
                  }}
                  link={{
                    type: "internal",
                    url: `/projects/${node.slug}/`,
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      )}

      <div className="mt-24 pb-7">
        <div className="container--boxed grid grid-cols-1 lg:grid-cols-2 gap-16">
          <section>
            <SectionHeader
              title="Courses"
              link={{
                type: "internal",
                url: "/courses/",
              }}
            />
            <ul className="list-disc marker:text-blue-400 mt-9 pl-5">
              {courses.edges.map(({ node }) => (
                <li key={node.id} className="mb-5 last:mb-0">
                  <a
                    href={node?.courseCustomFields?.link}
                    className="text-lg font-semibold transition hover:underline hover:underline-offset-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {node.title}
                  </a>
                  {node?.courseCustomFields?.highlightText && (
                    <span className="block text-slate-400 mt-2">
                      {node?.courseCustomFields?.highlightText}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </section>
          <section>
            {/* <SectionHeader
              title="Andrew's Letters"
              link={{
                type: "external",
                url: "https://read.deeplearning.ai/the-batch/",
              }}
            /> */}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    config: site {
      siteMetadata {
        siteUrl
        title
      }
    }

    currentPage: wpPage(slug: { eq: "home" }) {
      ...WpPageFields
      featuredImage {
        node {
          publicUrl
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }

    publications: allWpPublication(
      limit: 3
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpPublicationCardFields
        }
      }
    }

    projects: allWpProject(
      limit: 2
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpProjectCardFields
        }
      }
    }

    courses: allWpCourse(
      limit: 5
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpCourseFields
        }
      }
    }
  }
`

export default IndexPage
