import React, { useState } from "react"
// import Select from "react-select"
import { addClasses } from "@lib/utils"
import Spinner from "@components/Spinner"
import { FiMail } from "react-icons/fi"

type Props = {
  layout?: "horizontal" | "vertical"
}

const SubscribeForm: React.FC<Props> = ({ layout = "horizontal" }) => {
  const [focus, setFocus] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  // const [selectedOption, setSelectedOption] = useState(null)
  const [loading, setLoading] = useState(false)

  const submitForm = async e => {
    if (e) e.preventDefault()

    setLoading(true)

    const isBrowser = typeof window !== "undefined"
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: e.target.subscribe_email.value,
        },
        {
          name: "firstname",
          value: e.target.subscribe_first_name.value,
        },
        {
          name: "lastname",
          value: e.target.subscribe_last_name.value,
        },
        {
          name: "what_country_are_you_located_in_",
          // value: e.target.subscribe_country.value,
          value: "Other",
        },
      ],
      context: {
        pageUri,
        pageName,
      },
    }

    const portalId = "5871640"
    const formGuid = "f0168156-c922-467a-9f32-349f6489830f"

    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: new Headers({
          "Content-Type": "application/json",
          Accept:
            "application/json, application/xml, text/plain, text/html, *.*",
        }),
      }
    )

    const responseData = await response.json()

    if (responseData.status === "error") {
      setError(true)
    } else {
      setSubmitted(true)
      setFocus(false)
      setError(false)
    }

    setLoading(false)
  }

  /*
  const selectChangeHandler = selectedOption => {
    setSelectedOption(selectedOption)
    const countryInput = document.getElementById("subscribe_country")
    countryInput.value = selectedOption.value
  }
	*/

  return (
    <form
      className="w-full flex-col"
      onSubmit={submitForm}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div
        className={`w-full flex flex-col  items-center ${
          layout === "vertical" ? "md:flex-col" : "md:flex-row"
        }`}
      >
        <input
          className={`input ${layout === "vertical" ? "md:mr-0" : "md:mr-4"}`}
          type="email"
          placeholder="Your email..."
          id="subscribe_email"
          required
        />
        {!focus && (
          <button
            type="submit"
            className={`button--primary mt-4 ${
              layout === "vertical" ? "md:mt-4" : "md:mt-0"
            }`}
          >
            <span className="text-lg mr-2">
              <FiMail />
            </span>
            Subscribe
          </button>
        )}
      </div>
      <div
        className={addClasses(
          "transform transition-all delay-200 duration-300 ",
          focus
            ? "max-h-full opacity-100 "
            : "max-h-0 opacity-0 overflow-hidden"
        )}
      >
        <div className="flex mt-8 xs:mt-4 ">
          <div className="relative w-1/2 mr-2">
            <label
              htmlFor="subscribe_first_name"
              className={addClasses(
                "label",
                focus ? "label--visible" : "label--hidden"
              )}
            >
              First Name
            </label>
            <input
              id="subscribe_first_name"
              type="text"
              className="input"
              placeholder="e.g. John"
              required
            />
          </div>
          <div className="relative w-1/2 ml-2">
            <label
              htmlFor="subscribe_last_name"
              className={addClasses(
                "label",
                focus ? "label--visible" : "label--hidden"
              )}
            >
              Last Name
            </label>
            <input
              id="subscribe_last_name"
              type="text"
              className="input"
              placeholder="e.g. Smith"
              required
            />
          </div>
        </div>

        {/* <div className="mt-8 xs:mt-4 ">
					<div className="relative ">
						<label
							htmlFor="subscribe_country"
							className={addClasses(
								formStyles.label,
								focus ? formStyles.labelVisible : formStyles.labelHidden
							)}
						>
							Country
						</label>
						<input
							type="hidden"
							name="subscribe_country"
							id="subscribe_country"
						/>
						<Select
							instanceId="jnkh67t5rtfy"
							placeholder="Select a country"
							value={selectedOption}
							onChange={selectChangeHandler}
							options={countryList}
							className={addClasses(
								formStyles.select,
								reactSelect.reactSelect,
								size === 'small' ? reactSelect.small : ''
							)}
						/>
					</div>
				</div> */}

        <div className="mt-8 xs:mt-4 mb-8">
          <button className="button--primary" type="submit" disabled={loading}>
            {loading && <Spinner />}
            {loading ? (
              "Loading..."
            ) : (
              <>
                <span className="text-lg mr-2">
                  <FiMail />
                </span>
                Subscribe
              </>
            )}
          </button>
        </div>
      </div>

      {submitted && (
        <p
          className={
            "bg-green-50 text-green-700 py-2 px-3 rounded-lg text-sm text-left mt-3"
          }
        >
          🎉 You&apos;ve been signed up to The Batch, the weekly newsletter on what matters in AI right now.
        </p>
      )}

      {error && (
        <p className="bg-red-50 text-red-600 py-2 px-3 rounded-lg text-sm text-left mt-3">
          ⚠️ Hmmmm, something wen&apos;t wrong. Please try again!
        </p>
      )}
    </form>
  )
}

export default SubscribeForm
