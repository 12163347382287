import React from "react"
import StyledLink from "@components/Link"

type Props = {
  title: string
  link?: {
    type: "internal" | "external"
    url: string
  }
}

const SectionHeader: React.FC<Props> = ({ title, link }) => {
  return (
    <div className="flex justify-between border-b border-b-slate-200 dark:border-b-slate-700 pb-4">
      <div>
        {/* <div className="h-[3px] w-8 bg-blue-600 mb-4"></div> */}
        <h2 className="text-lg sm:text-[22px] font-semibold text-neutral-900 dark:text-slate-200">
          {title}
        </h2>
      </div>
      <div className="">
        {/* <div className="w-full h-[3px] mr-4 bg-slate-200"></div> */}
        {link?.url && (
          <StyledLink
            variant="arrow"
            url={{ type: link?.type, href: link?.url }}
          >
            View all
          </StyledLink>
        )}
      </div>
    </div>
  )
}

export default SectionHeader
